.errorMessage {
  width: 100vw;
  border-radius: 4px;
  z-index: 3;
  font-weight: 600;
  font-size: 12px;

  :global(.ui-alert__icon) {
    margin: 0px;
  }
}
