@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../../../common/styles/sizes";
@import "../../../../common/styles/colors";
@import "../../../../common/styles/peopleTable";

.table {
  min-width: 0;
  margin-left: -9px;
  > div {
    > div {
      &:last-child {
        flex-grow: 0.5;
        padding: 8px 0;
      }
    }
  }

  &Wrapper {
    scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar:vertical {
      width: 1rem;
      height: 1rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      background-clip: content-box;
      border-bottom-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      border-left-width: 0.25rem;
      border-bottom-width: 0.25rem;
      border-right-width: 0.25rem;
      border-top-width: 0.25rem;
      border-left-style: solid;
      border-bottom-style: solid;
      border-right-style: solid;
      border-top-style: solid;
      border-bottom-left-radius: 0.5625rem;
      border-bottom-right-radius: 0.5625rem;
      border-top-right-radius: 0.5625rem;
      border-top-left-radius: 0.5625rem;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
  .tableHeader {
    border: none;
    @include responsive.for-minsize("tablet") {
      padding-left: 9px;
    }
  }

  .tablePeopleAvailable {
    padding: 12px 24px 12px 8px;
    flex-grow: 0.55;
  }

  .lastCol {
    justify-content: flex-end;
  }

  .tableRowDark {
    height: fit-content;
    border-bottom-color: black;
    min-height: auto;
    @include responsive.for-minsize("tablet") {
      padding-left: 8px;
    }
    &:hover {
      background-color: #2e2e2e;
    }
  }

  .tableRowDefault {
    height: fit-content;
    min-height: auto;
    @include responsive.for-minsize("tablet") {
      padding-left: 8px;
    }
    &:hover {
      background-color: #ebebeb;
    }
  }

  .tableSegmentNameCol {
    flex-basis: 10%;
    font-size: 15px;
    overflow: hidden;
    padding: 8px 12px;
    > div {
      display: flex;
      align-items: center;
    }
    @include responsive.for-minsize("tablet") {
      flex-basis: 15%;
      padding: 12px;
      font-size: 12px;
    }
  }

  .headerTrip {
    padding: 8px 12px;
  }

  .buttonCellMobile {
    justify-content: flex-end;
    align-self: start;
  }
}

.tableHeader {
  border: none;
}

.tripInfoContent {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.itinerary {
  display: flex;
  flex-direction: column;
}

.tableIcon {
  padding-top: 5px;
}

.infoRow {
  font-size: 12px;
}

.infoRowDate {
  font-size: 10px;
}

.infoRowMobile {
  font-size: 13px;
}

.infoRowDateMobile {
  font-size: 12px;
}
