@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.date {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 12px;
}

.box {
  position: relative;
  border: 1px solid;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  width: 100%;
  margin-bottom: 12px;
}

.policy {
  padding: 8px 16px;
  font-weight: 400;
  font-size: 13px;
}

@include responsive.for-minsize("tablet") {
  .date {
    font-size: 14px;
  }

  .policy {
    font-size: 12px;
  }
}
