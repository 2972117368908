@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.equipments-wrapper {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.equipment {
  display: flex;
  align-items: center;

  &--name {
    padding-left: 8px;
    font-weight: 400;
    font-size: 13px;
  }
}

@include responsive.for-minsize("tablet") {
  .equipments-wrapper {
    gap: 25px;
  }

  .equipment {
    &--name {
      font-size: 12px;
    }
  }
}
