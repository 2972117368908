@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.settingsWrapper {
  height: 100vh;
  overflow: auto;
}

.headerTitle {
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.sectionTitle {
  margin-top: 5.5vh;
  margin-bottom: 0px;
}

.sectionItem {
  margin-top: 16px;
  margin-bottom: 0px;
}

.accountButton {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  width: auto;
  padding: 10px 14px;
}

.loggedIn {
  white-space: nowrap;
}

button.userProfile {
  padding: 0px;
  width: fit-content;
  span {
    line-height: 19px;
    font-weight: 400;
    font-size: 12px;
  }
  &:hover {
    text-decoration: underline;
  }
}

button.support {
  padding: 0rem;
  justify-content: left;
  span {
    line-height: 1.1875rem;
    font-weight: 400;
    font-size: 0.75rem;
  }
  &:hover {
    text-decoration: underline;
  }
}

button.legacyUserProfile {
  padding: 0px;
  width: fit-content;
  span {
    line-height: 19px;
    font-weight: 600;
    font-size: 14px;
  }
}

.settingsLayout {
  margin: auto;
  padding-top: 32px;
  width: 90%;
  height: 100%;
}

.justifySelfRight {
  justify-self: end;
  align-self: center;
  padding: 0px !important;
}

p,
h3 {
  margin: 0px;
}

.footer {
  width: 100%;
  text-align: center;
  font-size: 13px;
  min-height: 20%;
  padding-bottom: 40px;
  padding-top: 40px;
  margin-top: 24px;
}

.dialog {
  width: 90% !important;
  max-width: 398px !important;
  padding: 32px !important;
}

@include responsive.for-minsize("desktopSmall") {
  .settingsLayout {
    width: 56%;
  }
}

@include responsive.for-minsize("desktopLarge") {
  .footer {
    font-size: 12px;
    width: 100%;
  }
  .settingsLayout {
    width: 48%;
  }
}

@include responsive.for-range("mobile") {
  button.accountButton {
    width: auto;
    align-self: flex-end;
  }
  .loggedIn {
    white-space: normal;
    margin-bottom: 0px !important;
  }
  button.accountButton,
  button.userProfile span,
  button.support span,
  button.legacyUserProfile span {
    font-weight: 500;
  }
  .footer {
    min-height: 20%;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-top: 24px;
  }

  // when screen is really small, buttons are stacked
  .dialog .ui-button {
    margin-bottom: 10px;
  }
}

@include responsive.for-range("tablet") {
  button.accountButton,
  button.userProfile span,
  button.support span,
  button.legacyUserProfile span {
    font-weight: 500;
  }

  button.accountButton {
    width: auto;
  }
}
