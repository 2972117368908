@mixin reset-button {
  background: none;
  border: none;
  padding: 0;
}
.tab-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  &__button {
    width: 35px;
    outline: inherit;
    font-family: "Segoe UI", sans-serif;
    font-size: 14px;
    color: #605e5c;

    &--inactive {
      @include reset-button;
      cursor: pointer;
    }

    &--active {
      @include reset-button;
      color: #6264a7;
      font-weight: 600;
      border-bottom: 3px solid #6264a7 !important;
      padding-bottom: 2px;
    }
  }
}
.tab-nav-background {
  > li {
    background-color: transparent;
    padding-left: 0px;
  }
  > li:hover {
    background-color: transparent;
  }
}
.tab-nav-background:hover {
  li:hover div div div span {
    border-bottom: 3px solid #cfcfcf;
    padding-bottom: 2px;
  }
}

.gapSegments {
  gap: 20px;
  height: 100%;
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
