.tooltip-container {
  width: 100%;
  cursor: default;

  &__image {
    width: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
}

.tooltip-content {
  padding: 12px 48px 12px 16px;

  &__title {
    margin: 0;
    padding: 0 6px 12px 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }

  &__description {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
}
