@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../common/styles/Utilities.scss";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  height: 100%;

  color: rgba(255, 255, 255, 1);

  &.white {
    background-color: white;

    @include responsive.for-touchDevicesandSmallMobile() {
      background-color: white;
    }

    &.tablet {
      background-color: white;
    }
  }
  &.dark {
    background-color: #292929;
  }
}

.loader {
  width: 100%;
  height: 100%;
  &.white {
    background-color: white;
  }
  &.dark {
    background-color: #292929;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: pxToRem(24px);
  gap: pxToRem(8px);

  width: 89%;
  height: 8%;
  &.white {
    color: #000000;
  }
  &.dark {
    color: rgba(255, 255, 255, 1);
  }

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-style: normal;
  text-align: center;

  .title {
    font-weight: 700;
    font-size: pxToRem(18px);
    line-height: pxToRem(24px);
  }
  .description {
    font-weight: 400;
    font-size: pxToRem(12px);
    line-height: pxToRem(16px);
    align-items: center;
  }
}

.centerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 82%;

  .img {
    max-height: pxToRem(160px);
    max-width: pxToRem(160px);
  }
  .layout {
    max-height: pxToRem(160px);
    max-width: pxToRem(160px);
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 17%;
}

.divider {
  height: pxToRem(3px);
  width: 89%;
  background: rgba(37, 37, 37, 0.02);
  border-bottom: 1px solid rgba(37, 37, 37, 0.05);
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 89%;
}

.continue {
  min-width: pxToRem(108px);
  height: pxToRem(32px);
  margin-left: pxToRem(8px);
  border-radius: pxToRem(4px);
  width: fit-content;
  &.white {
    background: #5b5fc7;
  }
  &.dark {
    background: #4f52b2;
  }
}

.cancel {
  min-width: pxToRem(96px);
  height: pxToRem(32px);
  border-radius: pxToRem(4px);
  width: fit-content;
  padding: 0px, pxToRem(12px), 0px, pxToRem(12px);
}
