@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.to-do-dialog {
  margin: 20px;
  padding: 24px 32px 12px 32px !important;
}

@include responsive.for-range("mobile") {
  .to-do-dialog {
    display: block !important;
    margin: 0;
    padding: 0 !important;
    width: 100% !important;
    height: 100%;

    :global(.tl) {
      max-height: 200%;
    }
  }
}
