@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "Utilities.scss";

// Fontsizes

.cardHeaderTitle {
  font-size: pxToRem(14px);
  font-weight: 700;

  @include responsive.for-range("mobile") {
    font-size: pxToRem(17px);
  }
}

.cardHeaderSubtitle {
  font-size: pxToRem(12px);

  @include responsive.for-range("mobile") {
    font-size: pxToRem(15px);
  }
}

.cardHighlightedMessage {
  font-size: pxToRem(18px);

  @include responsive.for-range("mobile") {
    font-size: pxToRem(22px);
  }
}

// INCLUDE THIS STYLES ON YOU ELEMENTS

// @import 'path/sizes';

// .selector {
//     @extend .componentHeader;
// }
