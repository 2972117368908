.divider {
  display: flex;
  margin: 4px 0;

  &::before {
    height: 1px;
    width: 100%;
    content: "";
  }
}
