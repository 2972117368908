@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.hotel-header {
  display: flex;
  margin: 16px;
  flex-direction: column;

  &__image-container {
    width: 64px;
    height: 64px;
    border: 1px solid;
    border-radius: 4px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  &__image {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  &__name {
    font-weight: 800;
    font-size: 15px;
    margin-right: 12px;
    line-height: 1.5;
  }

  &__address {
    font-weight: 400;
    font-size: 13px;

    &-mobile {
      padding-top: 16px;
    }
  }

  &__nights {
    font-weight: 800;
    font-size: 15px;
  }

  &__nights-mobile {
    font-weight: 400;
    font-size: 15px;
  }

  &__wrapper {
    display: flex;
  }

  &__rating-stars {
    padding-top: 4px;
    flex-shrink: 0;
  }

  &__star {
    color: #ffb900;
    padding-right: 4px;
  }

  &__name-stars-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__bookin-code {
    font-weight: 800;
    font-size: 15px;
  }
}

@include responsive.for-minsize("tablet") {
  .hotel-header {
    flex-direction: row;
    justify-content: space-between;

    &__name-stars-wrapper {
      flex-direction: row;
    }

    &__name {
      font-weight: 700;
      font-size: 14px;
    }

    &__rating-stars {
      padding-right: 10px;
      flex-shrink: 0;
    }

    &__address {
      font-size: 12px;
      padding-top: 8px;

      &-mobile {
        padding-top: 0;
      }
    }

    &__nights {
      font-weight: 700;
      font-size: 14px;
    }
  }
}
