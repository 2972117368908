@import "../../../common/styles/Utilities.scss";

.search-list-wrapper {
  margin-bottom: 0;
}

.traveler-list-item {
  display: inline-block !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  align-content: center;
  vertical-align: middle;
  height: 50px;
  width: 100%;
  &.mobile {
    width: 100%;
    height: 80px;
    min-height: fit-content;
  }
}

.traveler-list-item > * {
  align-content: center;
  vertical-align: middle;
}

.list-container * {
  margin-right: 0 !important;
}

.list-container {
  li:last-child {
    margin-bottom: 82px;
  }
  &.mobile {
    &::-webkit-scrollbar:vertical {
      height: 0;
      width: 0;
      display: none;
    }
  }
  li {
    padding-left: 0;
    padding-right: 0;
  }
  &.pagination {
    li:last-child {
      margin-bottom: 130px;
    }
    &.mobile {
      li:last-child {
        margin-bottom: 100px;
      }
    }
  }
}

.pagination-component {
  align-items: center;
  position: fixed;
  bottom: 90;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;
  padding-top: 8px;
  padding-bottom: 8px;
  width: calc(100% - 64px);
  &.mobile {
    width: calc(100% - 32px);
    &.mobile-keyboard-visible {
      position: static;
    }
  }
}

.traveler-radio-button {
  width: 16px;
  margin: 16px;
  align-self: center;
}

.traveler-image {
  height: 32px;
  width: 32px;
  align-self: center;
  margin-left: 16px;
  margin-right: 2px;
  &.mobile {
    height: pxToRem(32px);
    width: pxToRem(32px);
  }
}

.traveler-name {
  width: 204px;
  justify-content: center;
  padding: 0 12px 0 12px;

  &.mobile {
    width: pxToRem(207px);
    min-height: fit-content;
    font-size: pxToRem(15px);
  }
}

.traveler-name * {
  overflow: hidden;
  text-overflow: ellipsis;
}

.traveler-location {
  width: 196px;
  align-self: center;
  align-content: center;
  overflow: hidden;
  padding: 0 12px 0 12px;
  text-overflow: ellipsis;

  &.mobile {
    width: pxToRem(207px);
    font-size: pxToRem(12px);
    min-height: fit-content;
    height: 70px;
  }
}

.traveler-favorite-icon {
  width: 20px;
  height: 20px;
  margin: 6px 16px 6px 8px !important;
  align-self: center;
  align-content: center;
  position: absolute;
  right: 0;

  &.mobile {
    height: pxToRem(20px);
    width: pxToRem(20px);
    margin: pxToRem(6px) pxToRem(10px) pxToRem(6px) pxToRem(16px) !important;
  }
}

.selected-traveler-label {
  width: fit-content;
  min-height: fit-content !important;
  height: 24px !important;
  margin-bottom: 0 !important;

  &.mobile {
    margin-bottom: 10px !important;
  }
}

.selected-traveler-name {
  margin-left: 4px;
  margin-right: 4px;
}

.search-favorite-container {
  padding-top: 18px;
  padding-bottom: 8px;
  &.mobile {
    margin-top: 0px !important;
  }
}

.text-major {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  &.mobile {
    font-size: 15px;
  }
}

.text-minor {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.booking-for-label {
  margin-top: 10px;
  &.mobile {
    margin-top: 0px;
  }
}

.list-divider {
  border-bottom: 1px solid;
  width: 100%;
  color: rgba($color: #252525, $alpha: 0.05);
}

.mobile-input-label {
  margin-top: 15px;
}

.search-list-header {
  position: sticky;
  top: 0;
  z-index: 2;
}
