.enableHotelShareToggle {
  width: 100%;
  text-align: right;
  font-size: 0.75rem;
}

.enableHotelShareToggleMobile {
  width: 100%;
  margin-top: 10px;
  font-size: 0.75rem;
  span {
    justify-self: end;
  }
}
