div.infoContainer {
  width: 398px;
  border-radius: 4px;

  .links {
    color: inherit;

    &:visited {
      color: inherit;
    }
  }
}

.settingsLink {
  text-decoration: underline;
  cursor: pointer;
  color: inherit;

  &:visited {
    color: inherit;
  }
}
