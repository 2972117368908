.enableAllToggle {
  width: 100%;
  text-align: right;
}

.enableAllToggleMobile {
  width: 100%;
  margin-top: 10px;
  span {
    justify-self: end;
  }
}
