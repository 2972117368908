.textEditor {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1.25rem 2rem 0rem 2rem;
  :global(.mobile) & {
    min-height: 28rem;
    max-height: fit-content;
  }
}

.container > div > .toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  border: 0;
}

.messageEditor {
  margin-top: 1rem;
}

.toolbar {
  border: 0;
}

:global(#toolbar) {
  border: 0;
}

.numberOfCharacters {
  width: 100%;
  text-align: end;
  font-size: 0.8rem;
  :global(.mobile) & {
    padding-top: 0.625rem;
    text-align: start;
  }
}

.buttons {
  flex-direction: row;
  gap: 0.5rem;
  padding: 1.75rem 0;
  margin-right: 1.875rem;
  margin-left: 1.875rem;
  > button {
    :global(.mobile) & {
      border-radius: 0.5rem;
    }
  }
}

:global(#quill) {
  min-height: 16.625rem;
  max-height: fit-content;
  :global(.mobile) & {
    min-height: 0rem;
    max-height: fit-content;
  }
}

:global(.ql-toolbar.ql-snow .ql-formats) {
  margin-right: 0rem;
}

.loading {
  min-height: 26.825rem;
  display: flex;
  justify-content: center;
}

.screenReaderText {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  margin: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
