@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../../../common/styles/sizes";
@import "../../../../common/styles/Utilities.scss";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.container {
  height: pxToRem(433px);
  padding: 12px 32px 0px 32px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.reasonsContainer {
  width: 100%;
  padding-bottom: pxToRem(8px);
}

.commentContainer {
  height: pxToRem(120px);
  min-height: pxToRem(120px);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.title {
  padding-top: pxToRem(12px);
  padding-bottom: pxToRem(20px);
  font-size: pxToRem(14px);
  text-align: left;
  font-style: normal;
  font-weight: 600;
  line-height: pxToRem(20px);
}

.comments {
  height: pxToRem(120px);
  min-height: pxToRem(120px);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  font-size: pxToRem(14px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(20px);
}

.commentSizeContainer {
  padding-top: pxToRem(2px);
  text-align: right;
  .commentSize {
    font-size: pxToRem(10px);
    font-style: normal;
    font-weight: 400;
  }
}

.description {
  font-size: pxToRem(14px);
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(20px);
}

.dropdown > div:nth-child(1) {
  font-size: pxToRem(14px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(20px);
}

.dropdown {
  height: pxToRem(32px);
}

.label {
  padding-bottom: pxToRem(4px);
  height: pxToRem(16px);
  align-self: stretch;
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(16px);
  > div:nth-child(1) {
    font-size: pxToRem(12px);
  }
}
