@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../common/styles/sizes";
@import "../../common/styles/Utilities.scss";

.shareContent {
  padding: 2rem;
  height: 85vh;
  overflow: auto;

  &.dark {
    .adaptiveCard {
      border: 1px solid #000000;
    }
  }
}

.shareContentMessaging {
  padding: 1.25rem;
  height: 90vh;
  overflow: auto;

  &.eventsShareContentMessaging {
    padding: 2.25rem;
  }

  .adaptiveCard {
    border: none;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0.9375rem;
  }
}

.adaptiveCardTrip {
  flex: 1;
}

.formBlock {
  margin-bottom: 1.5rem;

  > span {
    @include responsive.for-touchDevicesandSmallMobile() {
      font-size: pxToRem(12px);
      .white & {
        color: #616161;
      }
    }
    .tablet & {
      font-size: pxToRem(12px);

      .white & {
        color: #616161;
      }
    }
  }

  textarea {
    @include responsive.for-touchDevicesandSmallMobile() {
      background-color: transparent;
      border-bottom: 0.5px solid #e0e0e0;
      border-radius: 0;
      padding-left: 0;
      font-size: pxToRem(16px);
    }
    .tablet & {
      background-color: transparent;
      border-bottom: 0.5px solid #e0e0e0;
      border-radius: 0;
      padding-left: 0;
      font-size: pxToRem(16px);
    }
  }

  label {
    margin-bottom: 12px;

    @include responsive.for-touchDevicesandSmallMobile() {
      font-size: pxToRem(12px);
      margin-bottom: pxToRem(5px);
      .white & {
        color: #616161;
      }
    }
    .tablet & {
      font-size: pxToRem(12px);
      margin-bottom: pxToRem(5px);
      .white & {
        color: #616161;
      }
    }
  }
}

.adaptiveCard {
  border: 1px solid #e1dfdd;
  border-radius: 4px;
  margin-top: 12px;

  .destinationPicture {
    width: 48px;
    height: 48px;
    border-radius: 4px;
  }

  .tripDate {
    margin-top: 0.25rem;
  }

  .divider {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .tripCardHeader {
    padding-left: 0;
    padding-right: 0;
  }

  .hiddenSegment {
    border: none;
    box-shadow: none;
    padding: 16px;
  }
}

.actions {
  padding: 1.5rem 2.9rem;

  &.eventsActions {
    padding: 1.5rem 2rem;
  }

  &.eventsActionsMobile {
    padding: 0px;
    margin: 0px;
  }

  @include responsive.for-touchDevicesandSmallMobile() {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: pxToRem(20px);
  }

  &.tablet {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: pxToRem(0px);
  }

  &.white {
    background-color: white;
  }
  &.dark {
    background-color: #292929;
  }
}

.buttons {
  @include responsive.for-touchDevicesandSmallMobile() {
    border-radius: 8px;
    height: pxToRem(40px);
    font-size: pxToRem(13px);
  }

  .tablet & {
    border-radius: 8px;
    height: pxToRem(40px);
    font-size: pxToRem(13px);
  }

  > span {
    @include responsive.for-touchDevicesandSmallMobile() {
      font-weight: 500;
    }
    .tablet & {
      font-weight: 500;
    }
  }
}

.formWrapper {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.accordionList > .AccordionTitle {
  padding: 1em;
  box-shadow: rgba(34, 36, 38, 0.15) 0px 1px 1px 1px;
  margin-bottom: 0.9375rem;
}

.shareTripDetails {
  .cardSegmentContent {
    font-size: 12px;
    padding-right: 20px;
  }
}

.topDate {
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.segmentIcon {
  min-width: 16px;
  &.air {
    max-width: 16px;
  }
}

.segment {
  .white & {
    &:hover {
      background-color: #f5f5f5;
    }
  }
  .dark & {
    &:hover {
      background-color: #3d3d3d;
    }
  }
}

.eventsTopMessage {
  padding-bottom: 0.6rem;
}

div.checkboxStyles {
  width: 100%;
  padding: 24 32 16 32;
}

div.buttonsStyles {
  padding: 6 32 16 32;
  align-self: end;
}

div.shareJoinContainer {
  height: 100%;
}
