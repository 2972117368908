@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.generic-info {
  width: 100%;
  min-height: 100vh;
  &__popup {
    min-height: auto;
    width: 100%;
    overflow: scroll;
  }

  @include responsive.for-range("mobile") {
    padding-left: 36px;
    padding-right: 36px;
  }

  &__header {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 32px;
    text-align: center;
    &__popup {
      margin-top: 1rem;
    }
  }

  &__footer {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 3em;
    text-align: center;
    display: block;
  }

  &__content {
    font-size: 14px;
    line-height: 20px;
    padding-top: 10px;
    text-align: center;
    font-weight: 400;
  }

  &__custom-link {
    display: inherit;
    color: inherit;
    text-decoration: none;
  }

  &__button {
    margin-top: 1.5rem;
    min-width: 220px;
    line-height: 19px;
    font-weight: 600;
    font-size: 14px;
  }

  &__img {
    @include responsive.for-range("mobile") {
      max-width: 300px;
    }
  }

  &__subtitle {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__link {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-left: 4px;
    svg {
      margin-left: 2px;
    }
  }

  &__loginButton {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: auto;
    line-height: 19px;
    font-weight: 600;
    font-size: 14px;
    min-height: 2.5rem;
  }

  &__imgButton {
    height: 20px;
    width: 20px;
    margin: 30px 40px;
  }

  &__openLink {
    > span {
      margin-bottom: 3px;
    }
  }

  .second_button {
    margin-top: 1rem !important;
  }

  &__linkButton {
    padding: 0 !important;
    height: inherit;
    vertical-align: inherit;

    &:hover {
      text-decoration: underline;
    }

    span {
      line-height: inherit;
      font-weight: inherit;
      font-size: inherit;
    }

    div {
      margin-left: 5px;
    }
  }
}
