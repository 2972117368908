@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

div.trip-card {
  box-shadow:
    0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.11);

  @media screen and (max-width: 600px) {
    max-width: 500px;
    width: 100%;
  }

  &__body {
    overflow: hidden;

    padding: 16px 20px 21px 20px;
  }

  &__line {
    border-bottom: 1px solid;
    width: 100%;
    margin-top: 12px;
  }

  &__date {
    font-size: 13px;
  }

  &__default {
    &:hover {
      background: #f5f5f5;
      box-shadow:
        0px 0.6px 1.8px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    }
  }
  &__dark {
    &:hover {
      background: #3d3d3d;
      box-shadow:
        0px 0.6px 1.8px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    }
  }
}

@include responsive.for-minsize("desktopSmall") {
  .trip-card {
    &__body {
      padding: 20px 32px 20px 32px;
    }

    &__date {
      font-size: 12px;
    }
  }
}
