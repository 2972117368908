@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.main_container {
  padding: 16px;
  padding-bottom: 100px;
  height: 100%;
  overflow: scroll;
}

@include responsive.for-minsize("tablet") {
  .main_container {
    padding: 20px;
    padding-bottom: 100px;
    height: 100%;
    overflow: scroll;
  }
}
