@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.widgets-grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
}

.widgets-grid-container-past-trips {
  display: grid;
  grid-template-columns: 0.75fr;
  gap: 16px;
  & > div {
    margin-right: 16px;
  }
}
.widgets-grid-container-past-trips-mobile {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  > div {
    height: auto;
  }
}

.widgets-grid {
  &__to-do-list {
    max-height: 545px;
    min-height: 270px;
  }
}

@include responsive.for-minsize("desktopLarge") {
  .widgets-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include responsive.for-range("tablet") {
  .widgets-grid {
    &__to-do-list {
      height: 388px;
    }
  }
}

@include responsive.for-minsize("desktopLarge") {
  .widgets-grid {
    &__to-do-list {
      height: 388px;
    }
  }
}
