.shareMyRideWidgetContainer {
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 486px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1;
}

.whiteOverlay {
  background-color: rgba(245, 245, 245, 0.8);
}

.darkOverlay {
  background-color: rgba(31, 31, 31, 0.8);
}

.spinner {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.segments {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.8125rem; // 13px
  color: #616161;
  padding-top: 1.1875rem; // 19px
  flex-wrap: wrap;

  & > *:first-child {
    padding-right: 0.875rem; // 14px
  }

  &__icons {
    display: flex;
    color: #1f74e2;

    & > * {
      padding-right: 0.625rem; // 10px
    }

    & > *:last-child {
      padding-right: 0; // 0px
    }
  }

  &__rail_w_hotel {
    padding-top: 0.0625rem; // 1px
  }
}
