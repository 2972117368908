@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.container {
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
}

.extended-details {
  padding-bottom: 0;
  margin: 16px 16px 0 16px;

  &__service-class {
    display: flex;
    align-items: center;
  }

  &__class {
    padding-left: 6px;
    padding-right: 12px;
    font-weight: 600;
    text-transform: lowercase;
  }

  &__class::first-letter {
    text-transform: capitalize;
  }

  &__destination {
    padding-left: 8px;
    border-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 82px;

    &--duration {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 24px 0;
    }
  }

  &__box {
    padding: 16px;
    margin-bottom: 16px;
    &--divider {
      padding: 8px 0 16px 0;
    }
  }

  &__info-button {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  &__icon {
    &--notRotated {
      transform: rotate(0);
      margin-left: 11px;
    }

    &--isRotated {
      transform: rotate(-180deg);
      margin-left: 11px;
    }
  }
}

.timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__circle--departure,
  &__circle--arrival {
    width: 8px;
    height: 8px;
    min-height: 8px;
    border-radius: 50%;
  }

  &__circle--departure {
    background-color: #8a8a8a;
    margin-top: 5px;
  }

  &__circle--arrival {
    border: 1px solid #8a8a8a;
    margin-bottom: 5px;
  }

  &__line {
    width: 1.3px;
    height: 100%;
    background: #8a8a8a;
  }
}

.details-destination {
  font-weight: 600;
  display: flex;
  gap: 8px;
}

@include responsive.for-minsize("tablet") {
  .container {
    padding: 18px 20px 20px 20px;
    font-size: 12px;
  }

  .extended-details {
    padding-bottom: 16px;
    margin: 0;

    &__services-container {
      display: flex;
      align-items: flex-start;
    }

    &__destination {
      padding-right: 12px;

      &--duration {
        padding: 0;
      }
    }
    &__reservation-info {
      border-left: 1px solid #e9eaf6;
    }
  }
}
