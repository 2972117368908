@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.dialog-header {
  display: flex;
  align-items: center;
  padding: 19px 16px 4px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);

  &__main-information {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &--title {
      font-weight: 700;
      font-size: 15px;
    }

    &--description {
      font-weight: 400;
      font-size: 12px;
    }
  }
}

@include responsive.for-minsize("tablet") {
  .dialog-header {
    margin: 0;
    padding: 0;
    vertical-align: top;
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    border: 0;

    &__logo {
      border-radius: 3px;
      width: 40px;
      height: 50px;
      margin: 0;
      margin-right: 12px;
    }

    &__title-container {
      display: block;
      &--title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
      }

      &--description {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__close-button-container {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}
