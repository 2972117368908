@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.widget-container {
  padding: 20px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;

  &:hover {
    box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem !important;
  }
}
