@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.imageOverlap {
  margin-right: -10px;
}

.imageOverlap span {
  background-color: #e0e0e0;
  color: #424242;
}

.imageOverlapDark span {
  background-color: #333333;
  color: #d6d6d6;
}

div.peopleAvailableStyles {
  margin-top: 12px;
  min-width: 150px;
  text-align: center;
  @include responsive.for-minsize("tablet") {
    margin-top: 0px;
  }
}

div.avatarsPosition {
  text-align: initial;
}
