@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

button.linkButton {
  padding: 0;
  height: inherit;
  vertical-align: inherit;
  margin-left: -2px;

  span {
    line-height: inherit;
    font-weight: inherit;
    font-size: inherit;
  }
}

.pinDialogHeader {
  font-weight: 600;
  font-size: inherit;
  line-height: 20px;
}

.container {
  padding-top: 1vw;
}

.alert {
  margin-left: 20px;
  margin-right: 20px;

  @include responsive.for-range("mobile") {
    margin-left: 16px;
    margin-right: 16px;
  }
}
