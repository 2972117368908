@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@use "../../../common/styles/Utilities.scss";

.searchContainer {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 8px;
  gap: 12px;
  width: 100%;
  height: 48px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 2;

  .searchInput {
    input {
      border-radius: 4px;
      background-color: inherit;
    }
  }
}

.mobileSearchContainer {
  .searchInput {
    input {
      height: 40px;
      background-color: inherit;
      font-size: 17px;
      line-height: 22px;
      font-weight: 400;
      padding-left: 0;
    }
  }
}
