@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../../styles/sizes";

.shareContent {
  padding: 2rem;
  height: calc(100% - 2px);

  &Result {
    @extend .shareContent;
    .result {
      height: calc(100% - 2px);
      &Message {
        flex: 1;
        padding-bottom: 68px;
        &Text {
          @extend .cardHighlightedMessage;
          margin-top: 24px;
        }
      }
    }
  }

  &.white {
    background-color: white;

    .adaptiveCard {
      border: 1px solid #e1dfdd;
    }
  }
  &.dark {
    background-color: #292929;
    .adaptiveCard {
      border: 1px solid #000000;
    }
  }
}

.formWrapper {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-right: -20px;
  height: 75vh;
  overflow: auto;
}

.formBlock {
  margin-bottom: 1.5rem;

  label {
    margin-bottom: 12px;
  }
}

.recipient {
  padding: 12px 0 19px;
}

.adaptiveCard {
  border: 1px solid #e1dfdd;
  border-radius: 4px;
  margin-top: 12px;
}

.adaptiveCard > *:first-child {
  border-radius: inherit;
}
