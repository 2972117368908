@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../../../common/styles/sizes";

@import "colors";
@import "presence";
@import "table";
@import "findacontact";
@import "card";
@import "confirmation";

.text {
  margin-right: 5px;
}
.blockOfText {
  font-weight: 600;
}
