@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../../../common/styles/sizes";

@import "../../../collaborators/common/styles/colors";
@import "../../../collaborators/common/styles/card";

.card {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-top: 40px;
}

.cardHeader {
  margin-bottom: 1rem;

  .title {
    margin-bottom: 0.5rem;
    @extend .cardHeaderTitle;
  }

  .subtitle {
    color: $font-color-secondary-white;
    @extend .cardHeaderSubtitle;
  }
}

.white {
  .cardHeader {
    .title {
      color: $font-color-white;
    }
  }
  .cardFooterContainer {
    box-shadow: 0px -6.4px 14.4px $footer-shadow-white;
  }
}

.dark {
  .cardHeader {
    .subtitle {
      color: $font-color-secondary-dark;
    }
  }
  .cardFooterContainer {
    box-shadow: 0px -6.4px 14.4px $footer-shadow-dark;
  }
}

.cardBody {
  flex: 1;
  overflow: auto;
  position: relative;

  @include responsive.for-range("mobile") {
    &::-webkit-scrollbar:vertical {
      height: 0;
      width: 0;
      display: none;
    }
  }

  .noCollaboratorsMsg {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    padding-top: 0.5rem;
    padding-bottom: 20px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: 1rem;
    @extend .cardHighlightedMessage;
  }

  .subTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}

.disabledCardBody {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  @extend .cardBody;
}

@include responsive.for-range("mobile") {
  .cardHeader {
    margin-bottom: 30px;
  }

  .label {
    font-size: 12px;
  }

  .cardFooterContainer {
    :global(.ui-button) {
      min-width: 5rem;
      min-height: 2.5rem;
      border-radius: 10px;
      padding: 0 1px;
    }
  }

  .cardFooterDescription {
    font-size: 12px;
    padding-left: 5px;
  }

  .white {
    .cardFooterDescription {
      color: $font-color-secondary-white;
    }
    .label {
      color: $label-color-white;
    }
  }

  .dark {
    .cardFooterDescription {
      color: $font-color-secondary-dark;
    }
    .label {
      color: $label-color-dark;
    }
  }
}
