.confirmation-container {
  height: 100%;
  padding: 16px;
  justify-content: center;

  &__confirmation-image {
    height: 140px;
    width: 140px;
  }

  &__error-image {
    height: 180px;
    width: 180px;
  }

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
  }

  &__subtitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }
}

.white {
  .confirmation-container {
    background-color: #fafafa;
    &__subtitle {
      color: #616161;
    }
  }
}

.dark {
  .confirmation-container {
    background-color: #1f1f1f;
    &__subtitle {
      color: #adadad;
    }
  }
}
