@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

/* Text */
.title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding-top: 16px;
  padding-bottom: 4px;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 12px;
}

.moreOptions {
  padding-top: 16px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 4px;
}

/* CardBookTripButtons */
:global .easy-dark {
  & :local .cardButtons {
    border-color: #616161 !important;
  }
}

:global .easy-default {
  & :local .cardButtons {
    border-color: #d1d1d1 !important;
  }
}

.cardButtons {
  border: 2px dashed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px !important;
  gap: 20px;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  min-height: 312px;
  background: transparent !important;
  @media screen and (max-width: 600px) {
    max-width: 500px;
    width: 100%;
    min-height: 200px;
  }
}

.cardBodyButtons {
  flex-direction: row;
  align-items: center;
}

.buttonDiv {
  width: 95px;
  height: 19px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height, or 136% */
  display: flex;
  align-items: center;
  text-align: center;
}

.buttonContainer {
  flex-direction: row;
  flex-wrap: wrap;
}

/* Image */
.imgDeactivedFT {
  width: 100px;
  height: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.imgActivedFT {
  width: 80px;
  height: 80px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
