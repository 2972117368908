@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../../../common/styles/sizes";
@import "../../../../common/styles/Utilities.scss";

.content {
  flex-direction: column;
  align-items: center;
  padding: 0px;

  &.white {
    background-color: white;
    color: #242424;

    @include responsive.for-touchDevicesandSmallMobile() {
      background-color: white;
      color: #242424;
    }

    &.tablet {
      background-color: white;
      color: #242424;
    }
  }
  &.dark {
    background-color: #292929;
    color: rgba(255, 255, 255, 1);
  }
}

.container {
  min-height: pxToRem(80px);
  max-height: pxToRem(433px);
  margin-top: 16px;
  padding: 12px 32px 32px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.imageContainer {
  height: pxToRem(308px);
  min-height: pxToRem(308px);
  max-height: pxToRem(308px);
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  .image {
    width: 100%;
  }
}

.textContainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.title {
  font-size: pxToRem(14px);
  text-align: left;
  font-style: normal;
  font-weight: 600;
  line-height: pxToRem(20px);
}

.description {
  font-size: pxToRem(14px);
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(20px);
}
