@import "../../../../common/styles/colors";
@import "../../../../common/styles/peopleTable";

.table {
  > div {
    > div {
      &:first-child {
        flex-grow: 0.1;
      }
      &:last-child {
        flex-grow: 0.2;
      }
    }
  }
}
