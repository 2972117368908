@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.resizedImage {
  .sun {
    position: absolute;
    width: 43px;
    top: 21px;
    left: 25px;

    @include responsive.for-range("mobile") {
      display: none;
    }
  }
}

.mobileImage {
  margin-bottom: -5%;
}
