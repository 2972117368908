.container {
  height: 180px;
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}
