.tooltip {
  position: relative;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 280px;
  border-radius: 4px;

  &__close-button {
    position: absolute;
    top: 6px;
    right: 8px;
    z-index: 20;
  }

  button {
    &:focus::after {
      border-color: transparent !important;
    }
  }

  .action-buttons {
    padding: 0 16px 12px;

    button {
      margin-top: 8px;
    }
  }
}
