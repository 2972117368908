.dropDown {
  border: 1px solid;
  border-radius: 4px;
  min-width: 100px;
  margin: 2px;
  > div {
    width: 100%;
  }
  ul[role="listbox"] {
    min-width: 100%;
    width: auto;
  }

  [role="button"] {
    > span {
      margin-inline: auto;
    }
  }
}
