@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.empty-to-do {
  max-width: 450px;
  margin: 0 auto;
  text-align: center;

  &__image {
    width: 190px;
    height: 57px;
    margin: 32px auto 0;
  }

  &__title-container {
    &--title {
      font-size: 18px;
      line-height: 24px;
      padding-top: 6px;
    }
  }

  &__helper-text {
    margin: 8px auto 32px;
    font-size: 14px;
    line-height: 20px;
  }
}

@include responsive.for-minsize("tablet") {
  .empty-to-do {
    &__title-container {
      width: 470px;
    }
  }
}

@include responsive.for-range("mobile") {
  .empty-to-do {
    &--title {
      width: 100%;
    }
  }
}

@include responsive.for-range("tablet") {
  .empty-to-do {
    margin: 0 auto 12px;
    &__image {
      margin-top: 0;
      width: 160px;
      height: 190px;
      background-size: 100% 100% !important;
    }
  }
}

@include responsive.for-range("desktopLarge") {
  .empty-to-do {
    margin: 0 auto 12px;

    &__image {
      margin-top: 0;
      width: 160px;
      height: 190px;
      background-size: 100% 100% !important;
    }
  }
}
