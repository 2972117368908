.container {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
}
.insideButton {
  gap: 2px;
}

.button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.newProductText {
  padding-right: 4px;
}

.newProductTextO {
  padding-right: 4px;
  padding-left: 4px;
}

.externalLinkIcon {
  vertical-align: sub;
  margin-left: 2px;
}

.screenReaderOnly {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
