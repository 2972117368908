@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.tableContainer,
.cardContentContainer {
  height: 100%;
  max-width: 100%;

  @include responsive.for-range("mobile") {
    max-width: calc(100vw - 40px);
  }
}

.table {
  min-width: 491px;
  width: 100%;
  max-height: 220px;

  .tableHeader {
    min-height: 2.5rem;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .roleHeader {
    flex-grow: 1.5;
  }

  .firstCol {
    flex-basis: 20%;
    overflow: hidden;
    min-width: 165px;
    > div:first-child {
      min-width: 0;
    }
  }

  .roleCol {
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    flex-basis: 0;
    word-break: break-word;
    flex-grow: 1.5;
    &:before {
      content: "";
      margin-top: auto;
    }
    &:after {
      content: "";
      margin-bottom: auto;
    }
    & > div {
      align-self: flex-start;
    }
  }

  .userRow {
    height: fit-content;
    @include responsive.for-minsize("desktopSmall") {
      min-height: 3rem;
    }
    min-width: 0;
  }

  .userNameContainer {
    align-items: center;
  }

  .userInformationContainer {
    font-size: 14px;
    height: 100%;
    justify-content: center;
    max-width: 117px;
  }

  .CloseCollaboratorsUserInformationContainer {
    font-size: 14px;
    height: 100%;
    justify-content: center;
    overflow: hidden;
  }

  .iconDisabledHover {
    @media (hover: none) {
      &:hover {
        color: $icons-color-white;

        svg {
          path:nth-child(1) {
            display: block;
          }

          path:nth-child(2) {
            display: none;
          }

          g:nth-child(1) {
            display: block;
            path:nth-child(2) {
              display: block;
            }
          }
          g:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }

  .options {
    justify-content: flex-end;
    flex-basis: 52px;

    @include responsive.for-range("mobile") {
      padding: 0;
    }

    .moreIcon {
      svg {
        path:nth-child(1) {
          display: none;
        }
        path:nth-child(2) {
          display: block;
        }
      }
    }
  }
}

.reverseTableFont {
  font-family: "Segoe UI", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.reverseTableTitle {
  @extend .reverseTableFont;
  margin-bottom: 4px;
}

.reverseTableDescription {
  @extend .reverseTableFont;
  margin-bottom: 20px;
}

.white {
  .reverseTableTitle {
    color: #4f52b2;
  }
  .reverseTableDescription {
    color: #242424;
  }
}

.dark {
  .reverseTableTitle {
    color: #7f85f5;
  }
  .reverseTableDescription {
    color: #ffffff;
  }
}

.reverseCollaborators__img {
  width: 145.775px;
  height: 92px;
  flex-shrink: 0;
}

.reverseCollaboratorsContainer {
  height: 100%;
}

// Loader
.loaderHeaderIcon {
  margin-right: 16px !important;
  margin-left: 8px;
}

.loaderIcon {
  margin-right: 8px !important;
}

.loaderChatIcon {
  margin-right: 8px;
  margin-left: 8px;
}

// User
.avatar {
  min-width: 2rem;
}

.userText {
  height: 100%;
  justify-content: center;
  min-width: 0;
}

// theme colours

.white {
  .firstCol,
  .roleCol,
  .roleHeader {
    color: $font-color-white;
  }
}

.dark {
  .options {
    .moreIcon {
      color: $icons-color-dark;
    }
  }
  .iconDisabledHover {
    @media not (hover: hover) {
      &:hover {
        color: $icons-color-dark;
      }
    }
  }
  .firstCol,
  .roleCol,
  .roleHeader {
    color: $font-color-secondary-dark;
  }
}

@include responsive.for-range("mobile") {
  .tableContainer:first-child {
    height: 200px;
  }

  .table {
    max-height: 336px;
    min-width: 100%;

    > div:first-child {
      position: sticky;
      inset: 0;
      z-index: 2;
      border-bottom: none;
    }

    :global(.ui-popup__content__content) {
      padding: 4px;

      :global(.ui-button__content) {
        font-weight: 400;
      }
    }

    .firstCol {
      flex-basis: 60%;
      min-width: 165px;
      > div:first-child {
        min-width: 0;
      }
    }

    .roleCol {
      padding: 0;
      white-space: normal;
      flex-basis: 10%;
    }

    .userInformationContainer {
      font-size: 13px;
      max-width: 117px;
      white-space: normal;
    }

    .CloseCollaboratorsUserInformationContainer {
      font-size: 13px;
      white-space: normal;
      overflow: hidden;
    }

    .userNameText {
      font-weight: 500;
      width: 150px;
    }

    .options {
      padding: 0;
      flex-basis: 0;
    }
  }
}

@include responsive.for-range("tablet") {
  .userNameText {
    max-width: 100px;
  }

  .tableContainer {
    height: 0;
  }

  .tableContainer:first-child {
    height: 200px;
  }
}

@include responsive.for-range("desktopSmall") {
  .userNameText {
    max-width: 180px;
  }

  .tableContainer {
    height: 0;
  }

  .tableContainer:first-child {
    height: 200px;
  }
}

@include responsive.for-range("desktopLarge") {
  .userNameText {
    max-width: 150px;
  }
}

.userNameText {
  font-weight: 500;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.notifiedLabel {
  font-size: 10px !important;
}

.roleText {
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.white {
  .roleText {
    color: $label-color-white;
  }
  .notifiedLabel {
    background-color: #e8ebfa;
    color: #616161;
  }
}
.dark {
  .roleText {
    color: $label-color-dark;
  }
  .notifiedLabel {
    background-color: #444791;
    color: #ffffff;
  }
}

.tableReverse {
  max-height: 336px;
  min-width: 100%;

  > div:first-child {
    position: relative;
    inset: 0;
    z-index: 1;
    border-bottom: none;
  }

  .firstColReverse {
    flex-basis: 20%;
    padding-right: 0;
  }

  .tripDatesCol {
    overflow: hidden;
    align-items: flex-start;
    max-width: 100px;
    height: 40px;
  }

  .menuColReverse {
    display: flex;
    max-width: 36px;
    align-items: center;
    align-self: center;
    padding: 0;
  }
}
