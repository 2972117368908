@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.expense-details-dialog {
  height: 600px;

  :global(.ui-dialog__header) {
    width: 100%;
  }

  :global(.ui-dialog__footer) {
    margin-top: auto;
  }
}

@include responsive.for-range("mobile") {
  .expense-details-dialog {
    margin: 0;
    padding: 0 !important;
    width: 100% !important;
    height: 100%;

    :global(.tl) {
      max-height: 200%;
    }

    :global(.ui-dialog__footer) {
      padding: 16px;

      :global(.at) {
        display: inline-flex;
      }

      :global(.lj) {
        width: initial;
      }
    }
  }
}
