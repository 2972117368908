@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.findaContact {
  width: 100%;
  margin-bottom: 1rem;

  ul {
    li {
      cursor: pointer;
    }
  }
}

// theme colors

.white {
  .findaContact {
    > div {
      background-color: $input-background-white;
    }
  }
}

.dark {
  .findaContact {
    > div {
      background-color: $input-background-dark;
    }
  }
}

@include responsive.for-range("mobile") {
  .white,
  .dark {
    .findaContact {
      border-bottom: 1px solid;
      border-radius: 0;

      > div {
        background-color: transparent;
      }

      :global(.ui-input__input) {
        color: $label-color-white;
        padding-left: 0;
        font-size: 15px;
      }
    }
  }

  .white {
    .findaContact {
      border-color: $border-color-white;
    }
  }

  .dark {
    .findaContact {
      border-color: $border-color-dark;
    }
  }
}
