@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.destination {
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  padding-bottom: 4px;
}

.itemsPlacement {
  align-items: flex-start;
  .tripNameDate {
    max-width: 90%;
  }
}

@include responsive.for-minsize("desktopSmall") {
  .destination {
    font-size: 18px;
    padding-bottom: 0;
  }
}

.screenReaderOnly {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
