.cardBody {
  align-content: center;
}
.textContent {
  font-weight: 600;
  font-size: 14px;
}
.cardComponent {
  margin-bottom: 24px;
  width: auto;
  flex: 1;

  @media screen and (max-width: 600px) {
    max-width: 500px;
    width: 100%;
  }

  .grid_container {
    gap: 16px;

    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media screen and (min-width: 600px) {
      grid-template-columns: repeat(2, minmax(267px, 427px));
    }

    @media screen and (min-width: 978px) {
      grid-template-columns: repeat(3, minmax(254px, 378px));
    }
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(4, minmax(254px, 1fr));
    }

    @media screen and (min-width: 1500px) {
      grid-template-columns: repeat(5, minmax(254px, 1fr));
    }
  }

  .grid_container_sidebar_expanded {
    gap: 16px;
    @media screen and (max-width: 980px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media screen and (min-width: 980px) {
      grid-template-columns: repeat(2, minmax(267px, 427px));
    }
    @media screen and (min-width: 1278px) {
      grid-template-columns: repeat(3, minmax(254px, 378px));
    }
    @media screen and (min-width: 1600px) {
      grid-template-columns: repeat(4, minmax(254px, 1fr));
    }

    @media screen and (min-width: 1850px) {
      grid-template-columns: repeat(5, minmax(254px, 1fr));
    }
  }
}
