@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.todo-list {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 0;

    &--text-wrapper {
      display: flex;
    }

    &--count {
      font-weight: 700;
      width: 18px;
    }

    :global(.ui-button__content) {
      font-weight: 400;
    }
  }
}

@include responsive.for-minsize("tablet") {
  .todo-list {
    &__item {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@include responsive.for-range("mobile") {
  .todo-list {
    &__item {
      &--text-wrapper {
        padding-bottom: 10px;
        font-size: 17px;
      }

      &--count {
        font-weight: 600;
      }

      :global(.ui-button__content) {
        font-size: 17px;
      }
    }
  }
}
