@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.dialog-body {
  display: block;
  min-height: 370px;
  margin-top: 8px;
  margin-bottom: 24px;

  &__image {
    width: 100%;
    height: 60px;
    margin-bottom: 18px;
  }

  &__form {
    &--max-input-length {
      font-size: 10px;
      text-align: right;
      margin-bottom: 16px !important;
    }

    label {
      font-size: 12px;
    }

    :global(.ui-form__input):first-child {
      margin-bottom: 0;
    }

    :global(.ui-form__datepicker):last-child {
      margin-top: 0;
      width: 100%;
    }

    :global(.ui-datepicker) {
      display: flex;
      width: 100%;

      :global(.ui-box) {
        flex: 1;
        width: 100%;
      }
    }
  }
}

.dialog-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 9px;
  margin-top: 17px;
}

.flex {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.flex--item {
  flex-basis: 50%;
}

@include responsive.for-range("mobile") {
  .content-wrapper {
    padding: 16px;
    min-height: 300px;
    height: calc(100% - 155px);
  }

  .dialog-body {
    &__image {
      margin-bottom: 30px;
    }

    &__form {
      height: 10% !important;

      &--input {
        border-bottom: 1px solid;

        :global(.ui-input__input) {
          background-color: transparent;
        }
      }

      &--date-picker {
        :global(.ui-input__input) {
          border-bottom: 1px solid;
          border-radius: 0;
          background-color: transparent;
        }

        :global(.ui-datepicker) {
          border-color: inherit;

          :global(.ui-box) {
            border-color: inherit;
          }
        }
      }
    }
  }

  .dialog-button-container {
    margin-right: 37px;
  }

  .flex {
    flex-direction: column;
    gap: 22px;
  }

  .flex--item {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 405px) {
  .dialog-button-container {
    margin: 17px 16px 0;
    display: flex;
    justify-content: center;

    &__button {
      width: 168px;
    }
  }
}

@media screen and (max-height: 850px) and (max-width: 640px) {
  .content-wrapper--space-for-calendar {
    height: calc(120% - 155px);
  }
}
