@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;

  &__heading {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      padding-left: 12px;
      padding-right: 12px;

      &:first-of-type {
        margin-right: 8px;
      }
    }
  }
}

@include responsive.for-range("mobile") {
  .header {
    flex-direction: column;
    align-items: flex-start;

    &__heading {
      font-size: 17px;
      margin-bottom: 12px;
    }

    &__button-container {
      display: grid;
      grid-template-areas: "header__button--view-expenses header__button--new-expense";
    }
  }
}
