@import "../../styles/Utilities.scss";

.cityOption {
  display: flex;

  &:hover {
    cursor: pointer;
  }
  .countryCode {
    width: pxToRem(16px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
  .lb {
    height: pxToRem(14.29px);
  }
}

.airportOption {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
  }
}

.icon {
  margin: 0 5;
}

.mmtAirportOption {
  padding-left: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  .countryCode {
    width: pxToRem(16px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
  .lb {
    height: pxToRem(14.29px);
  }
}

.airportName {
  width: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: start;
  margin-top: 1px;
  margin-right: 10.29px;
  font-size: 14px;
}

.airportName.mmtAirportName {
  width: 100%;
}

.cityIcon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 9px;
  margin-left: 10px;
}

.dropdownFlag {
  height: 14.29px;
  margin-top: 3;
  margin-right: 6;
}

.singleAirportName {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: start;
  margin-top: 1px;
  margin-right: 10.29px;
  font-size: 14px;
}

.airportIcon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 8px;
  margin-left: 23px;
}

.singleAirport {
  width: 20px !important;
  height: 20px !important;
  margin-right: 9px;
  margin-left: 10px;
}
