@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.image-section {
  width: 100%;

  &__title {
    font-size: 14px;
    padding-bottom: 6px;
  }

  &__image {
    width: 255px;
    height: 60px;
  }

  &__button {
    padding: 0 4px !important;
    margin-top: 0;

    :global(.ui-box) {
      margin-right: 12px;
      margin-left: -6px;
    }
  }
}

.todo-list-section {
  flex: 1;
  height: calc(100% - 128px - 16px);

  position: relative;

  &__scroll-helper {
    &--top {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }

    &--bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
    }
  }
}

.list-of-to-dos {
  width: 100%;
  height: 367px;
  margin: 0 !important;
  scroll-padding: 16px !important;
  overflow-y: auto;
  overflow-x: hidden;

  :global(.ui-list__item) {
    padding: 0;

    margin: 0 0 8px 0 !important;

    &:last-child {
      margin-bottom: 0 !important;
    }

    :global(.ui-list__itemcontent) {
      margin: 0;
    }
  }
}

@include responsive.for-range("tablet") {
  .wrapper {
    flex-direction: row;
  }

  .image-section {
    max-width: 200px;

    &__image {
      width: 90%;
      height: calc(348px - 46px - 44px);
      background-size: 100% 100% !important;
    }

    &__button {
      margin-top: 15px;
    }
  }

  .todo-list-section {
    height: 100%;
  }

  .list-of-to-dos {
    height: 100%;
  }
}

@include responsive.for-minsize("desktopLarge") {
  .wrapper {
    flex-direction: row;
  }

  .image-section {
    width: 200px;

    &__image {
      width: 90%;
      height: calc(348px - 46px - 44px);
      background-size: 100% 100% !important;
    }

    &__button {
      margin-top: 15px;
    }
  }

  .todo-list-section {
    height: 100%;
  }

  .list-of-to-dos {
    height: 100%;
  }
}

@include responsive.for-range("mobile") {
  .image-section {
    &__image-container {
      display: flex;
      justify-content: center;
    }

    &__button {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 390px) {
  .todo-list-section {
    height: calc(100% - 148px - 16px);
  }

  .image-section {
    &__button {
      margin-top: 0;
    }
  }
}
