@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 0;

  &__title-wrapper {
    display: flex;
  }

  &__count {
    font-weight: 700;
    width: 18px;
    margin-right: 8px;
  }

  &__label {
    font-size: 10px !important;
    margin-left: 8px !important;
    height: 16px !important;
  }

  &:last-child {
    padding-bottom: 0;
  }

  :global(.ui-button) {
    border: 0;
  }

  :global(.ui-button__content) {
    font-weight: 400;
  }
}

@include responsive.for-minsize("tablet") {
  .list-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__title-wrapper {
      display: inline-block;
    }
  }
}

@include responsive.for-range("mobile") {
  .list-item {
    &__text-wrapper {
      width: 100%;
      padding-bottom: 10px;
      font-size: 17px;
    }

    &__count {
      font-weight: 600;
    }

    &__title-container {
      display: flex;
      justify-content: space-between;
    }

    &__label {
      flex-shrink: 0;
      margin-top: 5px;
    }

    :global(.ui-button__content) {
      font-size: 17px;
      margin-left: 25px;
    }
  }
}
