@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.loading {
  height: 100%;
}

.divider {
  padding-top: 0.5rem;
  display: flex;
  border-bottom: 1px solid;
}

.subtitle {
  font-size: 0.75rem;
  text-align: center;
  width: 100%;
  display: block;
}

.link {
  margin-top: -0.125rem;
  &:hover {
    text-decoration: underline;
  }

  > span {
    white-space: normal;
    font-size: 0.75rem;
    font-weight: 400;
  }

  > div {
    margin-left: 0.25rem;
    margin-bottom: auto;
  }
}

button.link {
  height: auto;
}

.marginBottom {
  margin-bottom: 0.5rem;
}
