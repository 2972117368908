@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.cytric-classic {
  height: 100%;

  &__container {
    width: 100%;
    margin: 0 auto;
  }

  &__loader {
    height: calc(100% - 100px);
  }
}

.cytric-classic__iframe {
  width: 100%;
  min-height: calc(100vh - 49px);
  border: none;

  &--none {
    display: none;
  }
}

@include responsive.for-minsize("desktopSmall") {
  .cytric-classic__iframe {
    min-height: calc(100vh - 47px);
  }
}
