@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.breadcrumb {
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  position: sticky;
  top: 0;
  z-index: 999;

  &__item {
    height: 19px;
    cursor: pointer;

    &:last-child {
      cursor: default;
      font-weight: 700;
    }
  }

  &__divider {
    font-family: Teams Assets, sans-serif;
  }

  &__mobileMenuWrapper {
    padding: 14px 0;
    margin: 0 20px;
    cursor: pointer;
  }

  &__mobileMenu {
    padding-right: 10px;
    font-size: 14px;
    font-weight: 700;
    vertical-align: middle;
  }
}

.link {
  text-decoration: none;
}

@include responsive.for-minsize("desktopSmall") {
  .breadcrumb {
    padding: 14px 8px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
    border-bottom: 0;
    z-index: 20;
  }
}
