.confirmationMessage {
  width: 80vw;
  height: 36px;
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #292828e5 !important;
  color: white !important;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  z-index: 1;
  opacity: 0;
  animation: deleteConfirmationMessage 10s ease-in forwards;

  @keyframes deleteConfirmationMessage {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    20% {
      opacity: 1;
      transform: translateY(0);
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
