@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.stop {
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 8px 8px 8px 18px;
  border: 1px solid;
  border-radius: 4px;
  margin-left: 16px;
  margin-right: 16px;
}

.duration-container {
  display: flex;
  align-items: flex-start;
}

.icon {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.text {
  padding-right: 4px;
  font-weight: 600;
}

.airport-change {
  padding-left: 6px;
  display: flex;
  flex-wrap: wrap;
}

@include responsive.for-minsize("tablet") {
  .stop {
    font-size: 12px;
  }

  .icon {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
  }

  .duration-container {
    display: flex;
    flex-direction: row;
  }
}
