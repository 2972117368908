.errorContainer {
  padding: 18px;
  position: relative;
}

Button.errorButton {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 1px;
  padding: 30px;
}

.errorIcon {
  width: 12px;
}

.errorImage {
  width: 160px;
}

.errorTitle {
  font-size: 18px;
  font-weight: 600;
  margin: 10px;
}

.errorSubtitle {
  font-size: 12px;
}
