@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../../common/styles/sizes";
@import "../../../common/styles/Utilities.scss";

.dropdownOptions ul {
  max-height: 7.5rem;
  overflow-y: auto;
}

.content {
  overflow-y: auto;
  height: 100vh; // fix for mozilla - dropdown openning up

  &.share-join {
    gap: 20px;
    padding: 0 0 20px 0;
    overflow-y: visible;
    height: calc(100vh - 180px); // fix for mozilla - dropdown openning up
  }
}

.main-text {
  margin-bottom: 0px !important;
  margin-top: 24px;
}

.flex {
  padding-top: 2.75rem;
}

.actions {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 32px;
  padding-bottom: 32px;
  width: 100%;

  &.mobile {
    padding: pxToRem(20px);
  }

  &.white {
    background-color: white;
  }
  &.dark {
    background-color: #292929;
  }
}

.buttons {
  font-size: pxToRem(14px);
  min-width: pxToRem(104px);
  width: fit-content;

  &.share-join-buttons {
    &.mobile {
      width: 100px;
      height: 40px;
      border-radius: 8px;
      font-size: 13px;
      min-width: fit-content;
    }
  }

  @include responsive.for-touchDevicesandSmallMobile() {
    border-radius: 8px;
    height: pxToRem(40px);
    font-size: pxToRem(13px);
  }

  .tablet & {
    border-radius: 8px;
    height: pxToRem(40px);
    font-size: pxToRem(13px);
  }

  > span {
    @include responsive.for-touchDevicesandSmallMobile() {
      font-weight: 500;
    }

    .tablet & {
      font-weight: 500;
    }
  }
}

.airportOrCityDropdown {
  height: pxToRem(32px);
  > :first-child ul {
    > li {
      height: pxToRem(31px);
    }
  }
}

.airportOrCityDropdown * input {
  background-color: inherit !important;
}

.airportOrCityOption {
  justify-content: flex-start;
  padding-left: 0.5rem;
}

.text-minor {
  font-size: 12px;
  font-weight: 400;

  @include responsive.for-touchDevicesandSmallMobile() {
    font-size: pxToRem(12px);
  }
}

.sj-loader {
  margin-top: 30%;
  &.mobile {
    margin-top: 65%;
  }
}
