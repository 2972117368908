@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.main_container_travelTab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.main_container {
  height: 100%;
  padding: 16px;
  width: auto;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;

  .grid_container {
    justify-content: start;
    gap: 16px;

    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media screen and (min-width: 600px) {
      grid-template-columns: repeat(2, minmax(267px, 427px));
    }

    @media screen and (min-width: 978px) {
      grid-template-columns: repeat(3, minmax(254px, 378px));
    }
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(4, minmax(254px, 1fr));
    }

    @media screen and (min-width: 1500px) {
      grid-template-columns: repeat(5, minmax(254px, 1fr));
    }
  }

  .grid_container_sidebar_expanded {
    gap: 16px;
    justify-content: start;

    @media screen and (max-width: 980px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media screen and (min-width: 980px) {
      grid-template-columns: repeat(2, minmax(267px, 427px));
    }
    @media screen and (min-width: 1278px) {
      grid-template-columns: repeat(3, minmax(254px, 378px));
    }
    @media screen and (min-width: 1600px) {
      grid-template-columns: repeat(4, minmax(254px, 1fr));
    }

    @media screen and (min-width: 1850px) {
      grid-template-columns: repeat(5, minmax(254px, 1fr));
    }
  }
}

.list-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  @include responsive.for-minsize("mobile") {
    margin-bottom: 16px;
  }
  @include responsive.for-minsize("tablet") {
    margin-bottom: 16px;
  }
  @include responsive.for-minsize("desktopSmall") {
    margin-bottom: 32px;
  }
}

.cardBody {
  flex: 1;

  position: relative;

  @include responsive.for-range("mobile") {
    &::-webkit-scrollbar:vertical {
      height: 0;
      width: 0;
      display: none;
    }
  }

  .noPastTripsMsg {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .subTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
  }
}

.disabledCardBody {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  @extend .cardBody;
}

.skeletonFirstCard {
  border: 2px dashed #d1d1d1 !important;
  border-radius: 4px;
}

.skeletonShape {
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.skeletonCardElementFirstCard {
  margin-top: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.skeletonCardShape {
  width: 100%;
  height: 50%;
}

.skeletonCardRoundShape {
  border-radius: 4px;
}

.skeletonCardLine {
  border-radius: 10px;
}

.skeletonCardBody {
  padding: 10px;
}

.skeletonCardElement {
  margin-top: 10px;
}

.skeletonCardLinePosition {
  border-radius: 10px;
  align-self: center;
}

.errorPastTrip {
  border: 1px solid #e0e0e0;
  padding-top: 3%;
  padding-bottom: 3%;
  text-align: center;
  justify-content: center;
  align-items: center;
  @extend .cardBody;
}

.borderSection {
  border-right: 1.5px solid #d1d1d1;
  padding-right: 16px;
}
.borderSectionDark {
  border-right: 1.5px solid #616161;
  padding-right: 16px;
}

.background {
  height: 130px;
  margin-right: -5px;
  padding-right: 5px;
}

.eventManagerSection {
  .sectionTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  .titleStyled {
    color: #4f52b2;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  .sectionDescription {
    padding-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  padding: 16px;
  max-width: 342px;
}
.imgEvent {
  flex-basis: 35%;
}

.screenReaderOnly {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.cancelAlertErrorDesktop {
  width: 140px;
  min-width: 140px;
}

.cancelAlertErrorMobile {
  margin-top: 40px;
}
