.merge-alert {
  &__text {
    font-weight: 700;
    padding-right: 6px;
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
    outline: 0;
    border: 0;
    background-color: transparent;
    font-weight: 700;
    color: inherit;
    padding: 0;
  }
}
