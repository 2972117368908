.accordion {
  position: relative;
  border: 1px solid;
  box-shadow:
    0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  width: 100%;
  margin-bottom: 12px;

  &__item {
    padding: 0 48px 0 16px;
  }

  &__arrow {
    position: absolute;
    right: 17px;
    z-index: 10;
    cursor: pointer;

    &--notRotated {
      transform: rotate(0);
    }

    &--isRotated {
      transform: rotate(-180deg);
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__footer {
    &--closed {
      margin: 10px 16px;
      font-size: 12px;
      font-weight: 400;
    }

    &--opened {
      margin: 8px 16px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.divider {
  border-bottom: 1px solid;
  width: 100%;
}
