.user {
  display: flex;
  padding: 4px 0;

  &__text {
    font-size: 12px;
    padding-left: 8px;
    line-height: 1.5;

    p:first-child {
      font-weight: 700;
    }
  }
}
