@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

@import "../../../../common/styles/sizes";
@import "../../../../common/styles/Utilities.scss";

.formBlock {
  margin-bottom: 1.5rem;

  > div {
    @include responsive.for-touchDevicesandSmallMobile() {
      background-color: transparent;
      border-bottom: 0.5px solid #e0e0e0;
      border-radius: 0;
      font-size: pxToRem(16px);
      padding-left: 0;
    }

    input {
      @include responsive.for-touchDevicesandSmallMobile() {
        padding-left: 0;
      }
      .tablet & {
        padding-left: 0;
      }
    }
  }

  &.tablet {
    > div {
      background-color: transparent;
      border-bottom: 0.5px solid #e0e0e0;
      border-radius: 0;
      font-size: pxToRem(16px);
      padding-left: 0;

      input {
        padding-left: 0;
      }
    }
    > div > div {
      background-color: transparent;
      border-radius: 0;
      border-bottom: 0.5px solid transparent;
      &:hover {
        background-color: transparent;
      }
    }
    label {
      font-size: pxToRem(12px);
      margin-bottom: pxToRem(5px);
    }

    &.white {
      label {
        color: #616161;
      }
    }
  }

  > div > div {
    @include responsive.for-touchDevicesandSmallMobile() {
      background-color: transparent;
      border-radius: 0;
      border-bottom: 0.5px solid transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }

  label {
    margin-bottom: pxToRem(12px);

    @include responsive.for-touchDevicesandSmallMobile() {
      font-size: pxToRem(12px);
      margin-bottom: pxToRem(5px);
    }
  }
}

.white {
  label {
    @include responsive.for-touchDevicesandSmallMobile() {
      color: #616161;
    }
  }
}
