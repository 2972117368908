.cardSideBarContainer {
  flex-direction: column;
  justify-content: start;
  border-left: 1px solid;
  min-height: 512px;
  max-width: 340px;
  height: 100%;
  overflow-y: scroll;
  padding: 16px;
}

.divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.bottomDivider {
  margin-top: 20px;
}

.white {
  .divider::before,
  .bottomDivider::before {
    background: #9299f7 !important;
  }
}

.dark {
  .divider::before,
  .bottomDivider::before {
    background: #444791 !important;
  }
}

.sideBarHidden {
  display: none !important;
}

.sideBarDisplay {
  display: flex !important;
  @media only screen and (min-width: 360px) and (max-width: 640px) {
    width: 100%;
    max-width: 100%;
  }
}

.buttonSideBar {
  position: absolute !important;
  top: 0 !important;
  border-radius: 0 !important;
  z-index: 2;
  padding-left: 8px !important;
  padding-right: 9px !important;

  padding-top: 12px !important;
  padding-bottom: 12px !important;
  border-right-width: 0px !important;
  border-top-width: 0px !important;
  height: 44px !important;

  .btnSideBarText {
    color: #7f85f5;
    margin-left: 4px;
  }

  .btnSideBarText:hover {
    color: #7f85f5;
    text-decoration: underline;
  }
}

.btnSideBarPositionHidden {
  right: 0 !important;
}

.btnSideBarPositionDisplay {
  @media only screen and (max-width: 640px) {
    right: 0 !important;
  }
  @media only screen and (min-width: 640px) {
    right: 340px !important;
    margin-right: -1px;
  }
}

.sideBarComponent {
  height: 500px;
}
