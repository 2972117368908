@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.status-pill {
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  box-sizing: border-box;
  border-radius: 14px;
  mix-blend-mode: normal;
  padding: 4px 12px;
  border: 1px solid;
}

@include responsive.for-minsize("tablet") {
  .status-pill {
    font-size: 12px;
  }
}
