.table {
  position: absolute;
  min-width: 560px;
  width: 100%;

  &Wrapper {
    min-height: 200px;
    overflow: auto;
    position: relative;
    flex: 1;
  }

  .options {
    justify-content: flex-end;
    flex-basis: 52px;

    .moreIcon {
      color: $icons-color-white;

      svg {
        path:nth-child(1) {
          display: none;
        }
        path:nth-child(2) {
          display: block;
        }
      }

      &:hover {
        color: $teams-color;

        svg {
          path:nth-child(1) {
            display: block;
          }
          path:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}

.tableHeader {
  height: 2.5rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableNameCol {
  flex-basis: 20%;
  overflow: hidden;
  > div {
    display: flex;
    align-items: center;
  }
}
