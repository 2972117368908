@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

@mixin personalizedButtonStyles {
  min-width: fit-content;
  border-radius: 8px;
  padding: 10 14 10 14;
}

button.removeFocus {
  &:focus,
  &:active,
  &:visited {
    background-color: white;
  }
}

button.enableButtonMobile {
  @include personalizedButtonStyles;
}

button.disableButtonMobile {
  @include personalizedButtonStyles;
  &:focus,
  &:active,
  &:visited {
    background-color: white;
  }
}

.SMRToggle {
  width: 2.7rem;
}

button.SMRButton {
  padding: 0 12px;

  &.enableButtonMobile,
  &.disableButtonMobile {
    > span {
      max-width: 65px;
    }
  }
  &.content {
    @include responsive.for-touchDevicesandSmallMobile() {
      min-width: auto;
    }
  }
  &.selection {
    width: 96px;
    min-width: 96px;
    @include responsive.for-touchDevicesandSmallMobile() {
      width: 70px;
    }
  }
}
