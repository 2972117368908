@media screen and (max-width: 640px) {
  .cancelTripButton {
    margin-left: -0.75rem;
    margin-top: -0.25rem;
  }
  .cancelDialog > div > div[role="group"] > div {
    justify-content: left;
    gap: 8px;
    button {
      width: 45%;
      height: 40px;
    }
  }
}

@media screen and (min-width: 640px) {
  .cancelTripButton {
    margin-left: -1rem;
    margin-top: -0.25rem;
  }
}

span > button.cancelTripButton:hover {
  color: inherit;
}

.cancelTripButton > span {
  font-weight: inherit;
}

div div .cancelDialog {
  max-width: 398px;
  @media screen and (max-width: 640px) {
    max-width: 90%;
  }
}
