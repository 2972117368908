@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "./sizes";
@import "./Utilities.scss";

.shareContent {
  padding: 2rem;
  height: calc(100% - 2px);

  form {
    justify-content: start;
  }

  &Result {
    @extend .shareContent;
    .result {
      height: calc(100% - 2px);
      &Message {
        flex: 1;
        padding-bottom: 68px;
        &Text {
          @extend .cardHighlightedMessage;
          margin-top: 24px;
        }
      }
    }
  }

  &.white {
    background-color: white;

    @include responsive.for-touchDevicesandSmallMobile() {
      background-color: #f5f5f5;
    }

    &.tablet {
      background-color: #f5f5f5;
    }

    .adaptiveCard {
      border: 1px solid #e1dfdd;
    }
  }
  &.dark {
    background-color: #292929;
    .adaptiveCard {
      border: 1px solid #000000;
    }
  }
}

.formWrapper {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-right: -20px;
  height: 75vh;
  overflow: auto;
}

.formBlock {
  margin-bottom: 1.5rem;

  > span {
    @include responsive.for-touchDevicesandSmallMobile() {
      font-size: pxToRem(12px);
      .white & {
        color: #616161;
      }
    }
    .tablet & {
      font-size: pxToRem(12px);

      .white & {
        color: #616161;
      }
    }
  }

  textarea {
    @include responsive.for-touchDevicesandSmallMobile() {
      background-color: transparent;
      border-bottom: 0.5px solid #e0e0e0;
      border-radius: 0;
      padding-left: 0;
      font-size: pxToRem(16px);
    }
    .tablet & {
      background-color: transparent;
      border-bottom: 0.5px solid #e0e0e0;
      border-radius: 0;
      padding-left: 0;
      font-size: pxToRem(16px);
    }
  }

  label {
    margin-bottom: 12px;

    @include responsive.for-touchDevicesandSmallMobile() {
      font-size: pxToRem(12px);
      margin-bottom: pxToRem(5px);
      .white & {
        color: #616161;
      }
    }
    .tablet & {
      font-size: pxToRem(12px);
      margin-bottom: pxToRem(5px);
      .white & {
        color: #616161;
      }
    }
  }
}

.adaptiveCard {
  border: 1px solid #e1dfdd;
  border-radius: 4px;
  margin-top: 12px;
}

.buttons {
  @include responsive.for-touchDevicesandSmallMobile() {
    border-radius: 8px;
    height: pxToRem(40px);
    font-size: pxToRem(13px);
  }

  .tablet & {
    border-radius: 8px;
    height: pxToRem(40px);
    font-size: pxToRem(13px);
  }

  > span {
    @include responsive.for-touchDevicesandSmallMobile() {
      font-weight: 500;
    }
    .tablet & {
      font-weight: 500;
    }
  }
}

.resultMessageText {
  text-align: center;
  font-size: pxToRem(18px);

  @include responsive.for-touchDevicesandSmallMobile() {
    font-size: pxToRem(17px);
  }
  .tablet & {
    font-size: pxToRem(17px);
  }
}

.actions {
  @include responsive.for-touchDevicesandSmallMobile() {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: pxToRem(20px);
  }

  &.tablet {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: pxToRem(20px);
  }

  &.white {
    background-color: white;
  }
  &.dark {
    background-color: #292929;
  }
}
