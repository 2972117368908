.taskmoduleContent {
  padding-left: 32px;
  padding-right: 32px;
}

.iconMedium svg {
  height: 16px;
  width: 16px;
}

.tableColumns {
  display: grid;

  &:hover {
    background-color: inherit !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
  }
}

.tableHeader {
  border-bottom-width: 0px !important;
}

.table {
  [role="row"] {
    [role="cell"] {
      padding-left: 4px;
    }
    [role="columnheader"] {
      padding-left: 4px;
    }
  }
  .firstCol {
    flex-basis: 41%;
  }

  .secondCol {
    flex-basis: 37%;
  }

  .thirdCol {
    flex-basis: 22%;
    > div:first-child {
      width: 100%;
    }
  }
}

.formBlockMobile {
  > div {
    background-color: transparent;
    border-bottom: 0.5px solid #e0e0e0;
    border-radius: 0;
    font-size: 16px;

    input {
      background-color: transparent !important;
    }
  }

  > div > div {
    background-color: transparent !important;
    border-radius: 0;
    border-bottom: 0.5px solid transparent;
  }
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
