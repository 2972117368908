@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.share-content {
  padding: 0 32px 0 32px;
  margin-top: 32px;
  min-height: calc(100vh - 90px);
  &.mobile {
    padding: 0 16px 0 16px;
    margin-top: 0;
    gap: 16px;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
}

.join-a-trip {
  &__line {
    border-bottom: 1px solid;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  &.dark {
    .adaptiveCard {
      border: 1px solid #000000;
    }
  }
}

.footer-shadow {
  border-bottom: 1px solid;
  color: rgba($color: #252525, $alpha: 0.05);
  position: fixed;
  bottom: 0;
  margin-bottom: 90px !important;
  width: calc(100% - 64px);

  &.mobile {
    width: calc(100% - 32px);
    margin-left: 0;
    left: 0;
  }
}

.cardHighlightedMessage {
  font-size: pxToRem(18px);

  @include responsive.for-range("mobile") {
    font-size: pxToRem(22px);
  }
}

.cardBody {
  flex: 1;
  overflow: auto;
  position: relative;

  @include responsive.for-range("mobile") {
    &::-webkit-scrollbar:vertical {
      height: 0;
      width: 0;
      display: none;
    }
  }

  .noCollaboratorsMsg {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    padding-top: 0.5rem;
    padding-bottom: 20px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: 1rem;
    @extend .cardHighlightedMessage;
  }

  .subTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}

.disabledCardBody {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  @extend .cardBody;
}

@include responsive.for-range("mobile") {
  .cardHeader {
    margin-bottom: 30px;
  }

  .label {
    font-size: 12px;
  }

  .cardFooterContainer {
    :global(.ui-button) {
      min-width: 5rem;
      min-height: 2.5rem;
      border-radius: 10px;
      padding: 0 1px;
    }
  }

  .cardFooterDescription {
    font-size: 12px;
    padding-left: 5px;
  }
}

.actions {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 90px;
  padding-right: 32px;

  &.mobile {
    padding: pxToRem(20px);
  }
}

.actions > * {
  align-self: center;
}

.text-major {
  &.mobile {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
}

.buttons {
  &.mobile {
    width: 100px;
    height: 40px;
    border-radius: 8px;
    font-size: 13px;
    min-width: fit-content;
  }
}

.radio-buttons {
  &.mobile {
    height: 28px;
    align-items: center;
    margin-top: 10px;
  }
}

.header {
  &.mobile {
    padding-top: 16px;
    margin-bottom: 0 !important;
  }
}
