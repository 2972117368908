.booking-details-widget {
  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
  }
  &__title {
    font-family: "Segoe UI", sans-serif;
    font-weight: 700;
    font-size: 14px;
  }
}

.body {
  height: 100%;
  display: flex;
  flex-flow: column;
}
