@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../../../common/styles/sizes";
@import "../../../../common/styles/Utilities.scss";

.container {
  height: 100%;
  padding: 20px;
  gap: 32px;
  @include responsive.for-range("mobile") {
    padding: 32px;
    gap: 20px;
  }
}

.image {
  width: 160px;
  @include responsive.for-range("mobile") {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.text {
  font-size: pxToRem(14px);
  text-align: center;
  @include responsive.for-range("mobile") {
    font-size: pxToRem(17px);
  }
}
