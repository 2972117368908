@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

@mixin status($background, $borderColor, $color) {
  background: $background;
  border: 1px solid $borderColor;
  color: $color;
}

.header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__date-and-status-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  &__date {
    font-size: 13px;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
  }

  &__titleMobile {
    font-weight: 600;
    font-size: pxToRem(17px);
  }

  &__destination-name {
    text-transform: capitalize;
  }

  &--status-to-be-approved,
  &--status-preapproved,
  &--status-approved,
  &--status-rejected,
  &--status-rejected-not-cancelled,
  &--status-rejected-not-canceled,
  &--status-unknown {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    box-sizing: border-box;
    border-radius: 12px;
    mix-blend-mode: normal;
    padding: 4px 12px;
  }
  &--status-to-be-approved {
    @include status(#e0e0e0, #c7c7c7, #292929);
  }

  &--status-preapproved {
    @include status(#e5e5f1, #bdbde6, #585a96);
  }

  &--status-approved {
    @include status(#e7f8d1, #237b4b, #237b4b);
  }

  &--status-rejected,
  &--status-rejected-not-canceled,
  &--status-rejected-not-cancelled {
    @include status(#fcf4f6, #f3d6d8, #c4314b);
  }

  &--status-unknown {
    @include status(#f5f5f5, #d1d1d1, #616161);
  }
}

@include responsive.for-minsize("tablet") {
  .header {
    &__date-and-status-container {
      flex-direction: row;
      align-items: center;

      & > *:first-child {
        padding-right: 10px;
      }
    }

    &__date {
      font-size: 12px;
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
    }

    &--status-to-be-approved,
    &--status-preapproved,
    &--status-approved,
    &--status-rejected,
    &--status-rejected-not-canceled {
      font-size: 12px;
    }
  }
}
