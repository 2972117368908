.to-do-task {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px;
  padding: 16px 24px 16px 16px;
  border-width: 1px;
  border-style: solid;

  :global(.ui-checkbox) {
    padding: 0;
  }

  :global(.ui-menu__item) {
    color: inherit;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  :global(.ui-popup__content) {
    z-index: 10;
  }

  :global(.ui-popup__content__content) {
    padding: 8px;

    :global(.ui-button) {
      display: flex;
      justify-content: flex-start;

      :global(.ui-button__content) {
        font-weight: 400;
      }
    }
  }

  &__more-options {
    cursor: pointer;
    position: relative;
    top: -5px;
  }

  &__title {
    :global(.ui-checkbox__label) {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      padding-right: 20px;
      word-break: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
  }

  &--done {
    :global(.ui-checkbox__label) {
      text-decoration: line-through;
    }
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 0 0 28px;
    &--done {
      text-decoration: line-through;
    }
  }
}

.submenu {
  display: flex;
  flex-direction: column;
}
