@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.placeholder {
  &__image {
    margin: 30px 0 20px;
    width: 210px;
    height: 210px;
  }

  &__imageMobile {
    margin-top: 20px;
    margin-bottom: -30px;
    width: 210px;
    height: 210px;
  }

  &__title {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }
}

@include responsive.for-minsize("tablet") {
  .placeholder {
    &__image {
      width: 100px;
      height: 100px;
    }

    &__title {
      font-size: 17px;
    }
  }
}
