@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.date {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 12px;
  font-size: 15px;
}

.accordion {
  margin-top: 12px;
}
.emptyDiv {
  text-align: center;
}

@include responsive.for-minsize("tablet") {
  .date {
    font-size: 14px;
  }
}
