@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.todo-list__title {
  margin: 0 0 20px 0;
}

@include responsive.for-range("mobile") {
  .todo-list__title {
    font-size: 17px;
    font-weight: 600;
  }
}
