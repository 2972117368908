@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.car-header {
  padding: 16px;

  &__main {
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
  }

  &__image-container {
    display: flex;
    align-items: center;
    width: 64px;
    height: 64px;
    border: 1px solid;
    border-radius: 4px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  &__image {
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  &__model-name {
    font-weight: 800;
    font-size: 15px;
    line-height: 1.5;
  }

  &__model-doors {
    font-weight: 400;
    font-size: 15px;
    display: block;
  }

  &__pickup-dropoff {
    font-weight: 400;
    font-size: 13px;
  }

  &__car-vendor {
    font-weight: 400;
    font-size: 13px;
    padding-top: 1px;
  }

  &__car-vendor-detail {
    padding-top: 8px;
  }
  &__reservationID {
    padding-top: 12px;

    &--heading {
      font-weight: 400;
      font-size: 13px;
    }

    &--number {
      font-weight: 800;
      font-size: 15px;
      padding-top: 4px;
    }

    &--mobile {
      padding-top: 16px;
    }
  }

  &__wrapper {
    display: flex;
    padding-right: 16px;
  }
}

@include responsive.for-minsize("tablet") {
  .car-header {
    &__main {
      flex-direction: row;
      justify-content: space-between;
    }

    &__model-name {
      font-weight: 700;
      font-size: 14px;
      margin-right: 8px;
    }

    &__model-doors {
      font-weight: 400;
      font-size: 14px;
      display: inline-block;
    }

    &__pickup-dropoff {
      font-size: 12px;
    }

    &__reservationID {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &--heading {
        font-weight: 400;
        font-size: 12px;
        padding-top: 0;
      }

      &--number {
        font-weight: 800;
        font-size: 15px;
        padding-top: 0;
      }
    }
  }
}
