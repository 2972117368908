@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.header {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  padding-top: 1.25rem;
  overflow: hidden;

  &__user-name {
    font-weight: 600;
    font-size: 22px;
  }

  &__welcome-message {
    font-size: 17px;
    margin-bottom: 16px;
  }

  &__plan-a-new-trip-button-icon {
    padding-right: 0.4rem;
  }

  &__search-flight-beta {
    margin-top: 10px;
    justify-content: center !important;
    white-space: nowrap;
  }
  &text-button-beta {
    font-weight: 400;
  }
  &__plan-a-trip-button {
    margin-top: 32px;
    white-space: nowrap;
  }
}

@include responsive.for-minsize("tablet") {
  .header {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    &__welcome {
      padding-right: 26px;
    }

    &__welcome-message {
      margin-bottom: 0;
    }
    &__search-flight-beta {
      justify-content: flex-end !important;
      white-space: nowrap;
    }
    &text-button-beta {
      font-weight: 400;
      white-space: nowrap;
    }
  }
}

@include responsive.for-minsize("desktopSmall") {
  .header {
    &__user-name {
      font-size: 20px;
    }

    &__welcome-message {
      font-size: 16px;
    }
  }
}
