@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

@mixin main-info {
  font-weight: 700;
  font-size: 15px;
  line-height: 1.5;
}

.onHold {
  padding: 12px 0 0 16px;
  gap: 4px;
  margin-bottom: -6px;
}

.onHoldText {
  font-size: 12px;
}

.basicDetails {
  padding: 16px 0;
  padding-right: 48px;
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;

  &__main-info {
    @include main-info;
    &--dash {
      vertical-align: text-bottom;
    }
  }

  &__city-info {
    @include main-info;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  &__provider-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 4px;
    border: 1px solid;
    border-radius: 4px;
    margin-right: 16px;
    margin-left: 16px;
    flex-shrink: 0;
    background-color: #ffffff;

    &--image {
      width: 100%;
      object-fit: contain;
    }
  }

  &__alert {
    margin: -2px 0 0 2px;
  }

  &__info-details {
    font-size: 13px;
    line-height: 1.5;
    font-weight: 400;
  }

  &__arrow-icon {
    margin: 3px 20px 0;
  }

  &__destinations {
    margin-left: 73px;
  }

  &__stops {
    @extend .basicDetails__destinations;
  }

  &__travelingFromTo {
    display: flex;
    justify-content: flex-start;
  }
}

@include responsive.for-minsize("tablet") {
  .basicDetails {
    gap: 16px;
    grid-template-columns: 36% 44% 1fr;
    &__main-info {
      font-size: 14px;
    }

    &__city-info {
      font-size: 14px;
    }

    &__info-details {
      font-size: 12px;
    }

    &__destinations {
      margin-left: 0;
    }

    &__travelingFromTo {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
