@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.dialog-body {
  .title {
    padding-bottom: 12px;
  }

  .list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

@include responsive.for-range("mobile") {
  .dialog-body {
    padding: 16px;
  }
}
