@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.segments {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  color: #616161;
  padding-top: 19px;
  flex-wrap: wrap;

  & > *:first-child {
    padding-right: 14px;
  }

  &__icons {
    display: flex;
    color: #1f74e2;

    & > * {
      padding-right: 20px;
    }

    & > *:last-child {
      padding-right: 0px;
    }
  }
}
