@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../../../common/styles/sizes";
@import "../../../../common/styles/colors";
@import "../../../../common/styles/peopleTable";

.shareMyRideContainer {
  padding: 24px 20px 0 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;

  @include responsive.for-minsize("tablet") {
    padding-top: 8px;
  }

  .shareMyRideHeader {
    > div {
      margin-bottom: 20px;
      flex: 1;
    }

    > button {
      min-width: 8rem;
      @include responsive.for-range("mobile") {
        margin-bottom: 0.8em;
      }
    }

    .shareMyRideTitle {
      @extend .cardHeaderTitle;
      margin-bottom: 4px;
      font-size: 14px;
    }

    .shareMyRideTitleMobile {
      @extend .cardHeaderTitle;
      margin-bottom: 8px;
      font-size: 17px;
      font-weight: 700;
    }

    .shareMyRideSubTitle {
      @extend .cardHeaderSubtitle;
      font-size: 12px;
      & > div {
        justify-content: space-between;
      }
    }

    .shareMyRideSubTitleMobile {
      @extend .cardHeaderSubtitle;
      font-size: 13px;
    }

    .moreInfo {
      margin-left: 2px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.lastCol {
  justify-content: flex-end;
  @include responsive.for-minsize("mobile") {
    flex-grow: 0.6;
  }
}

.name {
  margin-left: 10px;
  width: 21ch;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}

.sameFlightLabel {
  margin-left: 8px;
  font-weight: 400;
  font-size: 10px !important;
  line-height: 12px !important;
}

.emptyList {
  margin-top: 40px;
  margin-bottom: 40px;
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;

  .noColleaguesText,
  .disabledShareText,
  .errorTitle {
    font-weight: 600;
    font-size: 18px;
  }

  .noColleaguesTextMobile,
  .disabledShareTextMobile,
  .errorTitleMobile {
    font-weight: 600;
    font-size: 17px;
  }

  .disabledShareText {
    text-align: center;
  }

  .errorTitle {
    text-align: center;
  }

  .errorSubtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
  }
  .emptyListText {
    text-align: center;
    max-width: 340px;
  }
}

.loaderWrapper {
  padding: 3rem;
}

.skeleton {
  display: flex;
  gap: 13px;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-top: 5px;
}

.skeletonRows {
  pointer-events: none;
  > div {
    &:last-child {
      flex-grow: 0.2;
    }
    > div {
      width: 100%;
      flex: 1;
    }
  }
}

.skeletonButton {
  > div {
    justify-content: flex-end;
  }
}

.skeletonAvatar {
  flex: 1;
  align-items: center;
}

.skeletonLine {
  height: 8px !important;
  border-radius: 4px;
}

.skeletonSeparator {
  margin-top: 3px;
  margin-bottom: 3px;
}

.backButton {
  min-width: 70px;
  @include responsive.for-range("mobile") {
    font-size: pxToRem(13px);
  }
}

.listContent {
  flex: 1;
}

.disabledPeopleAvailable {
  margin-top: 15px;
  font-size: 10px;
}

.disabledAvatar {
  margin-top: 10px;
}

.disabledAvatar span {
  background-color: #e0e0e0;
  color: #424242;
}

.disabledAvatarDark {
  margin-top: 10px;
}

.disabledAvatarDark span {
  background-color: #333333;
  color: #d6d6d6;
}

.mainInfo {
  font-size: 15px;
  @include responsive.for-minsize("tablet") {
    font-size: 12px;
  }
}

.mainInfoMobile {
  font-size: 15px !important;
}

.smrSubtitleContainer {
  width: 80%;
}

.enableAllToggleContainerMobile {
  width: 100%;
  align-self: flex-end;
}
