@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.container {
  margin-left: 0;

  &__bold-text {
    font-weight: 600;
  }

  &__normal-text {
    color: #616161;
    font-weight: 400;
    white-space: pre;
  }

  &__paragraph {
    padding-bottom: 8px;
  }
}

.emissions-value {
  text-transform: lowercase;
}

@include responsive.for-minsize("tablet") {
  .container {
    margin-left: 16px;

    &__paragraph {
      padding-bottom: 12px;
    }
  }
}
