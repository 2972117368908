@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.contact-information {
  font-size: 13px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 9px;

  &__phone {
    :nth-child(2) {
      padding-left: 8px;
    }
    margin-bottom: 10px;
  }

  &__email {
    :nth-child(2) {
      padding-left: 8px;
    }
    margin-bottom: 25px;
  }

  &__phone,
  &__email {
    display: flex;
    align-items: center;
  }
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@include responsive.for-minsize("tablet") {
  .contact-information {
    font-size: 12px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 4px;

    &__email {
      margin-bottom: 0;
    }

    &__phone {
      margin-bottom: 0;
    }
  }
}
