@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.date {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 12px;
}

.box {
  position: relative;
  border: 1px solid;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  width: 100%;
  margin-bottom: 12px;
}

.footer {
  padding: 13px 16px;
}

.image-wrapper {
  height: 23px;
  margin-top: 12px;
}

.car-logo {
  object-fit: contain;
  max-height: 23px;
}

@include responsive.for-minsize("tablet") {
  .date {
    font-size: 14px;
  }

  .image-wrapper {
    margin-top: 0;
  }

  .footer {
    display: flex;
    justify-content: space-between;
  }
}
