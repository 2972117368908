@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.expense-item {
  display: flex;
  padding: 12px 0 8px 0;

  .image {
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
  }

  .details {
    padding-left: 12px;
    width: 100%;

    .heading-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 4px;

      .trip-name {
        font-size: 18px;
        font-weight: 400;
        margin-right: 8px;
        line-height: 22px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .date {
      font-size: 12px;
    }

    .description {
      padding: 4px 0 0 0;
      line-height: 20px;
    }

    .reimbursement {
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;

      .receipts {
        display: flex;
        align-items: center;
        margin-right: 12px;

        & > p {
          padding-left: 7px;
        }
      }

      .amount {
        font-weight: 700;
      }
    }
  }
}

@include responsive.for-range("mobile") {
  .expense-item {
    .details {
      .heading-row {
        .trip-name {
          font-size: 17px;
        }
      }

      .description {
        font-size: 15px;
      }

      .reimbursement {
        .receipts {
          & > p {
            font-size: 13px;
          }
        }

        .amount {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
}
