@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";
@import "../../../../common/styles/Utilities.scss";

.footer {
  width: 100%;
  padding: pxToRem(32px);
  flex-direction: column;
  align-items: flex-end;
}

.noCommentsFooter {
  height: pxToRem(91px);
}

.withCommentsFooter {
  height: pxToRem(96px);
}

.buttons {
  width: pxToRem(536px);
  height: pxToRem(32px);
  gap: pxToRem(8px);
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.continue {
  min-width: pxToRem(108px);
  height: pxToRem(32px);
  border-radius: pxToRem(4px);
  width: fit-content;
  > span {
    padding-bottom: pxToRem(4px);
  }
  &.white {
    background: #5b5fc7;
  }
  &.dark {
    background: #4f52b2;
  }
}

.cancel {
  min-width: pxToRem(96px);
  height: pxToRem(32px);
  border-radius: pxToRem(4px);
  width: fit-content;
  > span {
    padding-bottom: pxToRem(4px);
  }
}
