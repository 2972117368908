@use "@amadeus-cytric/cytric-teams-react-common-library/dist/styles/responsive";

.footer-container {
  padding: 12px 20px;
  z-index: 2;

  &__button {
    border-radius: 8px !important;
  }
}

.white {
  box-shadow: 0px -6.4px 14.4px rgba(0, 0, 0, 0.08);
  background-color: white;
}

.dark {
  box-shadow: 1px -19px 26px -27px rgb(255, 255, 255);
  background-color: #292929;
}

@include responsive.for-minsize("tablet") {
  .footer-container {
    &__button {
      border-radius: 4px !important;
    }
  }
}
